import { getPublicLayout } from 'layouts/PublicLayout'
import { cn } from 'lib/utils'
import { GetStaticPropsContext } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { HomepageSections, Routes } from 'constants/common/routes'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Button } from 'components/ui/Button'
import { Icon } from 'components/ui/Icon'
import { Link } from 'components/ui/Link'
import { Heading1, Heading2, Heading3, Paragraph } from 'components/ui/Typography'
import { serverSideTranslations } from 'utils/i18n'

const FAQ = [
  {
    q: 'Who needs to report?',
    a: 'Any business entity created by filing documents with a state or similar office must report. This includes corporations, limited liability companies (LLCs), and more.',
  },
  {
    q: 'When do I need to report:',
    a: (
      <>
        <p>
          Existing businesses (Formed before January 1, 2024): These businesses have a grace period
          and are required to file their initial BOIR by January 1, 2025.
        </p>
        <p>
          New businesses (Formed on or after January 1, 2024): For companies formed after the start
          of 2024, the filing window tightens. These businesses need to submit their BOIR within 90
          days of receiving notice that their business is officially registered or formed. This
          notice can come from your state’s Secretary of State office or through a public
          announcement.
        </p>
        <p>
          After January 1, 2025, any newly registered business will need to file the BOIR within 30
          calendar days from the date on which the company is created.
        </p>
      </>
    ),
  },
  {
    q: 'What information do I need?',
    a: "You'll need to provide details like your company's name, address, formation jurisdiction, and tax identification number. In addition, for each “Beneficial Owner,” you must share their legal name, birthdate, address, and ID details.",
  },
  {
    q: 'What Qualifies Someone as a Beneficial Owner of a Reporting Company?',
    a: (
      <>
        <p>A beneficial owner is an individual who, either directly or indirectly:</p>
        <p>
          Exercises Substantial Control: Substantial control means an individual may have zero
          ownership of the entity but possesses the authority to make critical decisions for the
          reporting company. This includes roles such as an officer, manager, having the authority
          to appoint board members, sell or lease major assets, engage in significant contracts, or
          other forms of important decision-making.
        </p>
        <p>Owns or Controls a Minimum of 25% Ownership Interests:</p>
        <p>
          Any individual with 25% or more of the ownership interests qualifies as a beneficial
          owner. This encompasses membership in an LLC, shares in a corporation, convertible notes
          and investment instruments, warrants, or any other form of ownership that either conveys
          ownership or can be converted into ownership. The threshold is individual, so, for
          instance, if someone owns 10% directly and an additional 30% through an LLC that invested
          in a reporting company, they would exceed the 25% threshold and need to be included as a
          beneficial owner.
        </p>
      </>
    ),
  },
  {
    q: 'Can you report legal entities?',
    a: 'In some cases, you can report legal entities instead of individual owners if certain conditions are met.',
  },
  {
    q: 'Do you need to update?',
    a: 'Yes, once your information changes, you must update your report within 30 days.',
  },
  {
    q: 'Are some companies exempt from the reporting requirement?',
    a: (
      <>
        <p>
          Yes, 23 types of entities are exempt from the beneficial ownership information reporting
          requirements. These entities include publicly traded companies meeting specified
          requirements, many nonprofits, and certain large operating companies.
        </p>
        <ol>
          <li>
            1. Large Operating Company Exemption: Requires 21 or more full-time employees and $5
            million or more in sales on the last business tax return.
          </li>
          <li>
            2. Securities reporting issuer under section 15(d) of the Securities Exchange Act of
            1934.
          </li>
          <li>3. Company with governmental authority on behalf of a State or Tribe.</li>
          <li>4. Registered Bank.</li>
          <li>5. Registered Credit Union.</li>
          <li>6. Registered Depository Institution Holding Company.</li>
          <li>7. Registered Money Services Business.</li>
          <li>8. SEC-registered broker or dealer in securities.</li>
          <li>9. SEC-registered securities exchange or clearing agency.</li>
          <li>10. Company registered under the Commodity Exchange Act.</li>
          <li>11. SEC-registered investment company or investment adviser.</li>
          <li>12. SEC-registered venture capital fund adviser.</li>
          <li>13. Registered Insurance Company.</li>
          <li>14. State-licensed insurance producer.</li>
          <li>15. Commodity Exchange Act registered entity.</li>
          <li>16. Registered Accounting Firm.</li>
          <li>17. Public Utility Company.</li>
          <li>
            18. Financial Market Utility Company designated by the Financial Stability Oversight
            Council.
          </li>
          <li>19. Pooled Investment Vehicle operated by an SEC registered person.</li>
          <li>20. Tax-Exempt Entity.</li>
          <li>
            21. Entity exclusively existing to provide financial assistance or governance to a
            tax-exempt entity.
          </li>
          <li>22. Entity 100% owned by an exempt entity.</li>
          <li>
            23. Inactive entity created before 1/1/2020 with no assets, business activity, foreign
            owners, or ownership changes in the prior 12 months.
          </li>
        </ol>
        <p>
          Before determining your company&apos;s exemption status, carefully review the criteria
          associated with each exemption.
        </p>
      </>
    ),
  },
]

const Home = () => {
  const { t } = useTranslation('common')
  return (
    <div>
      <Head>
        <title>{t('appName')}</title>
        <meta
          name="description"
          content="Don't risk navigating the complexities of beneficial ownership reporting on your own. File it simply with FileMyBOIR.org, the Expert Filing Assistance for FinCEN BOIR."
        />
        <meta name="title" content="Secure & Easy Beneficial Ownership Information Report (BOIR)" />
        <meta
          property="og:description"
          content="Don't risk navigating the complexities of beneficial ownership reporting on your own. File it simply with FileMyBOIR.org, the Expert Filing Assistance for FinCEN BOIR."
        />
        <meta
          property="og:title"
          content="Secure & Easy Beneficial Ownership Information Report (BOIR)"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <section className="bg-surface">
        <div className="ml-auto mr-auto px-6 sm:max-w-[800px] xl:max-w-[1280px]">
          <div
            className={cn(
              'py-4 grid gap-6 items-center',
              'sm:justify-center sm:text-center',
              'xl:py-14 xl:grid-cols-[1fr_minmax(0,_620px)] xl:gap-12 xl:text-left',
            )}
          >
            <div>
              <Heading1>
                Secure & Easy Beneficial Ownership Information Report (BOIR) Filing
              </Heading1>
              <Paragraph className="mt-6 font-poppins text-primary-700">
                Don&apos;t risk navigating the complexities of beneficial ownership reporting on
                your own. File it simply with FileMyBOIR.org, the Expert Filing Assistance for
                FinCEN BOIR.
              </Paragraph>
              <div className="flex flex-col xl:flex-row gap-6 md:gap-8 mt-8">
                <div>
                  <Button variant="secondary" className="hidden sm:inline-flex mr-2" asChild>
                    <Link href={Routes.Quiz()}>Am I required to file?</Link>
                  </Button>
                  <Button variant="secondary" className="sm:hidden mr-2" asChild>
                    <Link href={Routes.Quiz()}>Am I required?</Link>
                  </Button>
                  <Button rightIcon={<Icon.ChevronRight size={20} />} asChild>
                    <Link href={Routes.Boir()}>Get started</Link>
                  </Button>
                </div>
                <div className="flex justify-center items-center">
                  <div className="w-[120px] h-[36px] relative">
                    <Image
                      src="/assets/images/accredited-business.png"
                      alt="test"
                      fill
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:flex sm:justify-center">
              <div className="max-w-[88vw] h-[242px] sm:h-[340px] md:h-[458px]">
                <Image
                  src="/assets/images/homepage-1.jpeg"
                  alt="test"
                  width={687}
                  height={458}
                  className="rounded-2xl"
                  style={{ objectFit: 'cover', height: '100%' }}
                />
              </div>
            </div>
          </div>
          <div className="py-6 grid flex-col xl:grid-cols-3 gap-2 items-center mb-[-3.5rem]">
            <div className="flex gap-4 p-6 rounded-2xl border border-surface-200 bg-surface">
              <div>
                <Icon.Check size={24} strokeWidth={1.8} className="text-primary" />
              </div>
              <div>
                <Paragraph className="text-primary text-lg font-bold font-condensed">
                  Compliance is mandatory
                </Paragraph>
                <Paragraph className="mt-2 text-base text-[0.9375rem] text-primary-700">
                  Avoid fines of up to $500/day or imprisonment for failing to comply
                </Paragraph>
              </div>
            </div>
            <div className="flex gap-4 p-6 rounded-2xl border border-surface-200 bg-surface">
              <div>
                <Icon.Check size={24} strokeWidth={1.8} className="text-primary" />
              </div>
              <div>
                <Paragraph className="text-primary text-lg font-bold font-condensed">
                  Filling BOIR is easy
                </Paragraph>
                <Paragraph className="mt-2 text-base text-[0.9375rem] text-primary-700">
                  Easily file the BOIR Report online, in a few simple steps.
                </Paragraph>
              </div>
            </div>
            <div className="flex gap-4 p-6 rounded-2xl border border-surface-200 bg-surface">
              <div>
                <Icon.Check size={24} strokeWidth={1.8} className="text-primary" />
              </div>
              <div>
                <Paragraph className="text-primary text-lg font-bold font-condensed">
                  Support of a trusted provider
                </Paragraph>
                <Paragraph className="mt-2 text-base text-[0.9375rem] text-primary-700">
                  File yourself with a step-by step guide or get help from our experts.
                </Paragraph>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary">
        <div
          className="ml-auto mr-auto px-6 sm:max-w-[800px] xl:max-w-[1280px]"
          id={HomepageSections.whatIsBoir}
        >
          <div className="pt-[4.5rem] lg:pt-[7.5rem] pb-8 lg:pb-20">
            <div className="flex justify-center">
              <div className="flex flex-col items-center max-w-[1000px]">
                <Heading2 className="text-3xl lg:text-4xl text-surface text-center">
                  What is the Corporate Transparency Act?
                </Heading2>
                <Paragraph className="mt-6 text-center text-base text-surface font-poppins">
                  Starting January 1, 2024, the Corporate Transparency Act (CTA) mandates most US
                  businesses report key information to the government, aimed at preventing money
                  laundering and terrorism financing. Unawareness won&apos;t protect you from
                  significant fines.
                </Paragraph>
              </div>
            </div>
            <div className="mt-14 grid gap-12 xl:grid-cols-[minmax(0,_620px)_1fr]">
              <div className="sm:flex sm:justify-center">
                <div className="max-w-[88vw] h-[242px] sm:h-[340px] md:h-[400px]">
                  <Image
                    src="/assets/images/homepage-2.jpeg"
                    alt="test"
                    width={620}
                    height={413}
                    className="rounded-2xl"
                    style={{ objectFit: 'cover', height: '100%' }}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-6 flex-grow">
                <Heading3 className="text-surface">What information is needed?</Heading3>
                <div>
                  <div className="flex gap-4 py-6 first:border-b last:border-t border-white/20">
                    <div>
                      <div className="p-3 bg-primary-100 rounded-lg">
                        <Icon.Building2 size={32} strokeWidth={1.5} className="text-primary" />
                      </div>
                    </div>
                    <div>
                      <Paragraph className="text-surface text-lg font-bold font-condensed">
                        Reporting Company details
                      </Paragraph>
                      <Paragraph className="mt-1.5 text-surface text-[0.9375rem]">
                        Information about the company, such as legal name, contact U.S. address, and
                        identification details.
                      </Paragraph>
                    </div>
                  </div>
                  <div className="flex gap-4 py-6 first:border-b last:border-t border-white/20">
                    <div>
                      <div className="p-3 bg-primary-100 rounded-lg">
                        <Icon.Contact size={32} strokeWidth={1.5} className="text-primary" />
                      </div>
                    </div>
                    <div>
                      <Paragraph className="text-surface text-lg font-bold font-condensed">
                        Company applicant information
                      </Paragraph>
                      <Paragraph className="mt-1.5 text-surface text-[0.9375rem]">
                        Information about the individual applicant(s), such as name, address, date
                        of birth and identification details.
                      </Paragraph>
                    </div>
                  </div>
                  <div className="flex gap-4 py-6 first:border-b last:border-t border-white/20">
                    <div>
                      <div className="p-3 bg-primary-100 rounded-lg">
                        <Icon.UsersRound size={32} strokeWidth={1.5} className="text-primary" />
                      </div>
                    </div>
                    <div>
                      <Paragraph className="text-surface text-lg font-bold font-condensed">
                        Beneficial Owner Information
                      </Paragraph>
                      <Paragraph className="mt-1.5 text-surface text-[0.9375rem]">
                        You&apos;ll need to provide information about the individuals behind your
                        corporation or LLC, such as names, addresses, and other pertinent details.
                      </Paragraph>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center xl:justify-start">
                  <Button rightIcon={<Icon.ChevronRight size={20} />} asChild>
                    <Link href={Routes.Boir()}>Get started</Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-surface">
        <div
          className="ml-auto mr-auto px-6 sm:max-w-[800px] lg:max-w-[1280px]"
          id={HomepageSections.howToFile}
        >
          <div className="flex flex-col gap-8 items-center py-8 lg:py-20">
            <Heading2 className="text-3xl lg:text-4xl text-center">
              How to file BOIR through us
            </Heading2>
            <div className="flex flex-col lg:flex-row gap-2">
              <div className="bg-surface-50 p-6 lg:p-8 rounded-2xl flex-1">
                <div className="p-3.5">
                  <Icon.FileText size={28} strokeWidth={1.5} className="text-primary" />
                </div>
                <div className="mt-6 lg:mt-8">
                  <Paragraph className="text-primary text-2xl font-bold font-condensed">
                    1. Fill in the form
                  </Paragraph>
                  <Paragraph className="mt-2 text-[0.9375rem]">
                    Follow the instructions accompanying the form and fill it in, step by step.
                  </Paragraph>
                </div>
              </div>
              <div className="bg-surface-50 p-6 lg:p-8 rounded-2xl flex-1">
                <div className="p-3.5">
                  <Icon.Download size={28} strokeWidth={1.5} className="text-primary" />
                </div>
                <div className="mt-6 mt-8">
                  <Paragraph className="text-primary text-2xl font-bold font-condensed">
                    2. Submit and download your transcript
                  </Paragraph>
                  <Paragraph className="mt-2 text-[0.9375rem]">
                    You&apos;ll instantly receive the status of your application
                  </Paragraph>
                </div>
              </div>
              <div className="bg-surface-50 p-6 lg:p-8 rounded-2xl flex-1">
                <div className="p-3.5">
                  <Icon.FileText size={28} strokeWidth={1.5} className="text-primary" />
                </div>
                <div className="mt-6 mt-8">
                  <Paragraph className="text-primary text-2xl font-bold font-condensed">
                    3. Get support
                  </Paragraph>
                  <Paragraph className="mt-2 text-[0.9375rem]">
                    Our team of experts are ready to assist you, just a phone call away.
                  </Paragraph>
                </div>
              </div>
            </div>
            <div>
              <Button variant="secondary" className="hidden sm:inline-flex mr-2" asChild>
                <Link href={Routes.Quiz()}>Am I required to file?</Link>
              </Button>
              <Button variant="secondary" className="sm:hidden mr-2" asChild>
                <Link href={Routes.Quiz()}>Am I required?</Link>
              </Button>
              <Button rightIcon={<Icon.ChevronRight size={20} />} asChild>
                <Link href={Routes.Boir()}>Get started</Link>
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-surface-50">
        <div
          className="ml-auto mr-auto px-6 sm:max-w-[800px] lg:max-w-[1280px]"
          id={HomepageSections.whyUs}
        >
          <div className="grid gap-8 lg:gap-12 py-8 lg:py-20 items-center xl:grid-cols-[minmax(0,_620px)_1fr]">
            <div className="sm:flex sm:justify-center">
              <div className="max-w-[88vw] h-[242px] sm:h-[340px] md:h-[400px]">
                <Image
                  src="/assets/images/homepage-3.png"
                  alt="test"
                  width={620}
                  height={412}
                  className="rounded-2xl"
                  style={{ objectFit: 'cover', height: '100%' }}
                />
              </div>
            </div>
            <div className="sm:flex sm:flex-col sm:items-center xl:items-start">
              <Heading2 className="text-3xl lg:text-4xl">Why file BOIR with us?</Heading2>
              <div className="mt-4 lg:mt-8">
                <div className="flex gap-4 py-2">
                  <div>
                    <Icon.Check size={24} strokeWidth={1.8} className="text-primary" />
                  </div>
                  <Paragraph className="text-primary text-lg font-bold font-condensed">
                    Time & money saving service
                  </Paragraph>
                </div>
                <div className="flex gap-4 py-2">
                  <div>
                    <Icon.Check size={24} strokeWidth={1.8} className="text-primary" />
                  </div>
                  <Paragraph className="text-primary text-lg font-bold font-condensed">
                    Immediate confirmed compliance
                  </Paragraph>
                </div>
                <div className="flex gap-4 py-2">
                  <div>
                    <Icon.Check size={24} strokeWidth={1.8} className="text-primary" />
                  </div>
                  <Paragraph className="text-primary text-lg font-bold font-condensed">
                    Monthly and Annual compliance checks
                  </Paragraph>
                </div>
                <div className="flex gap-4 py-2">
                  <div>
                    <Icon.Check size={24} strokeWidth={1.8} className="text-primary" />
                  </div>
                  <Paragraph className="text-primary text-lg font-bold font-condensed">
                    Talk to US based customer service support
                  </Paragraph>
                </div>
              </div>
              <div className="mt-4 lg:mt-8 flex gap-6">
                <Button rightIcon={<Icon.ChevronRight size={20} />} asChild>
                  <Link href={Routes.Boir()}>Get started</Link>
                </Button>
                <div className="flex justify-center items-center">
                  <div className="w-[120px] h-[36px] relative">
                    <Image
                      src="/assets/images/accredited-business.png"
                      alt="test"
                      fill
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-surface">
        <div className="ml-auto mr-auto px-6 max-w-[1280px]" id={HomepageSections.faq}>
          <div className="flex flex-col items-center py-8 lg:py-[4.5rem] gap-6">
            <Heading2 className="text-3xl lg:text-4xl">Frequently asked questions</Heading2>
            <div className="w-full max-w-[752px]">
              <Accordion variant="flush" type="single" collapsible>
                {FAQ.map(({ q, a }, index) => (
                  <AccordionItem key={q} className="bg-surface" value={`accordion-item-${index}`}>
                    <AccordionTrigger className="text-base font-semibold text-start">
                      {q}
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="py-6 flex flex-col gap-2">{a}</div>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
            <div>
              <Button rightIcon={<Icon.ChevronRight size={20} />} asChild>
                <Link href={Routes.Boir()}>Get started</Link>
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

Home.getLayout = getPublicLayout

export const getStaticProps = async (context: GetStaticPropsContext) => ({
  props: {
    ...(await serverSideTranslations(context, ['common'])),
  },
})

export default Home
