import * as React from 'react'
import { HiChevronDown } from 'react-icons/hi'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'lib/utils'

type AccordionVariant = 'flush' | 'default' | 'separated'

const accordionVariants = {
  root: cva('', {
    variants: {
      variant: {
        flush: '',
        default: '',
        separated: '',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }),
  item: cva('', {
    variants: {
      variant: {
        flush: 'border-b border-surface-200 group',
        default: '',
        separated: 'mt-3 first:mt-0',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }),
  trigger: cva('text-primary', {
    variants: {
      variant: {
        flush:
          '[&>svg]:text-slate-900 dark:[&[data-state=open]>div>svg]:text-white dark:[&>svg]:text-slate-400 dark:[&[data-state=open]>svg]:text-white dark:[&[data-state=open]]:text-white [&>div>svg]:text-slate-800 dark:[&>div>svg]:text-slate-400',
        default: '',
        separated: '',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }),
  content: cva('', {
    variants: {
      variant: {
        flush: 'border-t border-surface-200',
        default: '',
        separated: 'border-t border-surface-200',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }),
}

const AccordionContext = React.createContext<AccordionVariant>('default')

const Accordion = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Root> &
    VariantProps<typeof accordionVariants.root>
>(({ className, variant, ...props }, ref) => (
  <AccordionContext.Provider value={variant || 'default'}>
    <AccordionPrimitive.Root
      ref={ref}
      className={cn(accordionVariants.root({ variant }), className)}
      {...props}
    />
  </AccordionContext.Provider>
))

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => {
  const variant = React.useContext(AccordionContext)

  return (
    <AccordionPrimitive.Item
      ref={ref}
      className={cn(accordionVariants.item({ variant }), className)}
      {...props}
    />
  )
})
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => {
  const variant = React.useContext(AccordionContext)

  return (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          accordionVariants.trigger({ variant }),
          'flex flex-1 items-center text-xl justify-between py-5 font-bold text-primary transition-all [&[data-state=open]>svg]:rotate-180',
          className,
        )}
        {...props}
      >
        <div className="flex gap-2 items-center w-full mr-2 justify-between">{children}</div>
        <HiChevronDown className="h-5 w-5 shrink-0 transition-transform duration-200 text-surface-900" />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
})
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => {
  const variant = React.useContext(AccordionContext)

  return (
    <AccordionPrimitive.Content
      ref={ref}
      className={cn(
        accordionVariants.content({ variant }),
        'overflow-hidden text-base transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
        className,
      )}
      {...props}
    >
      <div>{children}</div>
    </AccordionPrimitive.Content>
  )
})
AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger }
