import { cn } from 'lib/utils'
import { useTranslation } from 'next-i18next'
import { HomepageSections, Routes } from 'constants/common/routes'
import { Button } from 'components/ui/Button'
import { Footer } from 'components/ui/Layout'
import { Link, LinkProps } from 'components/ui/Link'
import { Logo } from 'components/ui/Logo'

const NavLink = ({ children, ...props }: LinkProps) => (
  <li>
    <Link
      {...props}
      className="text-base text-[0.9375rem] text-primary-700 font-semibold hover:underline hover:underline-offset-4"
    >
      {children}
    </Link>
  </li>
)

export const PublicLayout = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation('common')

  return (
    <>
      <header
        className={cn(
          'flex justify-between items-center gap-2 z-10',
          'font-condensed font-bold text-primary text-lg md:whitespace-nowrap',
          'w-full h-[3.25rem] md:h-16 md:sticky md:top-0 bg-surface py-2.5 px-6',
          'border-b border-b-surface-100',
        )}
      >
        <div className="">
          <Link href={Routes.Home()} aria-label="home">
            <Logo titleId="header-logo" className="w-[123px] h-[34px] md:w-[154px] md:h-[44px]" />
          </Link>
        </div>
        <nav className={cn('hidden invisible md:block md:visible justify-self-center')}>
          <ul className="flex gap-8">
            <NavLink href={`#${HomepageSections.whatIsBoir}`} scroll={false}>
              {t('navigation.whatIsBoir')}
            </NavLink>
            <NavLink href={`#${HomepageSections.howToFile}`} scroll={false}>
              {t('navigation.howToFile')}
            </NavLink>
            <NavLink href={`#${HomepageSections.whyUs}`} scroll={false}>
              {t('navigation.whyUs')}
            </NavLink>
            <NavLink href={`#${HomepageSections.faq}`} scroll={false}>
              {t('navigation.faq')}
            </NavLink>
            <NavLink href={`#${HomepageSections.contact}`} scroll={false}>
              {t('navigation.contact')}
            </NavLink>
          </ul>
        </nav>
        <div>
          <Button size="sm" asChild>
            <Link href={Routes.Boir()}>Get started</Link>
          </Button>
        </div>
      </header>
      {children}
      <Footer />
    </>
  )
}
